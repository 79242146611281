import React from 'react'
import styled from 'styled-components'
import NetlifyForm from 'react-netlify-form'
import fetch from 'isomorphic-fetch'
import * as PropTypes from "prop-types"
import formDataEntries from "form-data-entries"
import getFormData from 'get-form-data'

const Submit = styled.input`
    background: #38D39F;
    color: white;
    width: 100%;
    border: 0;
    padding calc(1em + 1px) 0;
    font-size: 1.25em;
    transition: .2s;
    font-family: Work Sans;
    font-weight: 800;
    text-transform: uppercase;
    :hover {
        cursor: pointer;
        background: #2FC492;
    }
    @media (min-width: 700px) {
        margin-left: -200px;
        max-width: 200px;
    }
`;

const Input = styled.input`
    border: 1px solid #e6e6e6;
    font-size: 1.25em;
    padding: 1em;
    width: 100%;
    margin: .5em 0;
    font-family: Work Sans;
    @media (min-width: 700px) {
        padding-right: 200px;
    }
`;

export default class Subscribe extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit(body) {
        
        let formData = {};
        for (const [name, value] of formDataEntries(
            document.forms[this.props.name ? this.props.name : "leconte"]
        )) {
            formData[name] = value;
        }

        formData["pageName"] = document.title;

        console.log(formData)

    }
  
    render() {
        return (
            <NetlifyForm name='leconte' onSuccess={this.handleSubmit}>
                {({ loading, error, success }) => (
                    <div>
                    {loading &&
                        <div>Loading...</div>
                    }
                    {error &&
                        <div>Your information was not sent. Please try again later.</div>
                    }
                    {success &&
                        <div>Thank you for contacting us!</div>
                    }
                    {!loading && !success &&
                        <div>
                            <Input name="email" type="email" placeholder="example@email.com" value={this.state.value}  onChange={this.handleChange} />
                            <Submit type="submit" value= "Sign Up"  />
                        </div>
                    }
                    </div>
                )}
                </NetlifyForm>
        );
    }
}