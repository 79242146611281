import React from 'react'
import styled from 'styled-components'

const Handle = styled.p`
    color: #38D39F;
    font-family: Work Sans;
    margin-bottom: 0;
`;

const Wrapper = styled.div`
    padding: 2em;
    border: 1px solid #e6e6e6;
    margin: .5em 0;
`;

const Tweet = styled.p`
    font-family: Work Sans;
`;

export default function Reservations({ tweet, tweetdate}) {
    return (
        <div>
        <Wrapper>
            <Tweet>"{tweet}"</Tweet>
            <Handle>- Tweeted by @LeconteLodge</Handle>
        </Wrapper>
      </div>
    )
}

