import React from 'react'
import styled from 'styled-components'

const Copy = styled.p`
    font-family: Work Sans;
    text-align: center;
    width: 90%;
    max-width: 700px;
    margin: 2em auto;
`;

const Link = styled.button`
    background: #38D39F;
    padding: .5em 1em;
    color: white;
    width: 220px;
    border: 0; 
    transition: .2s;
    font-family: Work Sans;
    text-decoration: none;
    :hover {
        cursor: pointer;
        background: #2FC492;
    }
`;


export default () => (
    <div>
       <Copy>Like this project? Help me fund it. <Link as="a" href="https://buymeacoff.ee/yTK6YD1lL" >Buy Me a Coffee</Link></Copy>
    </div>
)