import React from "react"
import { graphql } from "gatsby"


import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Subscribe from "../components/forms/subscribe"
import Reservations from "../components/openings"
import Buymecoffee from "../components/buymecoffee"


import styled from 'styled-components'

const Flexbox = styled.div`
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`;

const FlexTwo = styled.div`
  @media (min-width: 900px) {
    flex: 2 100%;
    padding: 3em;
  }
`;

const SmallWrapper = styled.div`
  width: 90%;
  max-width: 900px;
  margin: 4em auto;
`;

const IndexPage = ({ data })  => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <Flexbox>
          <FlexTwo>
      <div style={{ width: `100%`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
        </FlexTwo>
        <FlexTwo>
      <h1 style={{ fontFamily: `Work Sans`, color: `#3F3D56`, fontSize: `3em` }}>Is there Room on LeConte?</h1>
      <hr style={{ background: `#38D39F`, height: `.5em`, width: `30%` }} />
      <p style={{ fontFamily: `Work Sans`, fontSize: `1.25em`, lineHeight: `1.5em` }}>Get an email notification whenever @LeConteLodge tweets about a room becoming available.</p>
      <Subscribe />
        </FlexTwo>
    </Flexbox>

    <Buymecoffee />

    <SmallWrapper>
      <h3 style={{ fontFamily: `Work Sans`, color: `#3F3D56`, fontSize: `2em`, textAlign: `center` }}>Recent Opening Announcements</h3>
    
      {data.allReservation.edges.map((items, key) => {
        return <Reservations key={key}
          tweet={items.node.tweet}
          tweetdate={items.node.publish_date}
        />
      })}
    </SmallWrapper>
 
  </Layout>
)

export default IndexPage

export const query = graphql`
query {
  allReservation (limit: 5, sort: { order: DESC, fields: [publish_date] }) {
    edges {
      node {
        publish_date
        reservation_date
        tweet
      }
    }
  }
}
`